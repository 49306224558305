.home-banner-image {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/home.jpeg");
    background-size: cover;
    background-position: top center;
    margin-top: -74px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3rem 0 60%;
    box-sizing: border-box;
    text-align: center;
    color: white;
}

.home-name {
    font-size: 4rem;
    padding-bottom: 1rem;
}
.hindi-name {
    font-size: 2rem;
    font-weight: 400;
    padding-bottom: 1rem;
}
.home-subtitle {
    font-size: 1.5rem;
    padding-bottom: 1rem;
}
.home {
    height: 10rem;
}

.download-link {
    color: white;
    font-size: 1.25rem;
}

.download-icon {
    margin: 0 0.75rem 0 0.25rem;
}

@media only screen and (max-width: 860px) {
    .home-banner-image {
        padding: 0 1rem 0 50%;
    }
    .home-name {
        font-size: 3rem;
    }
    .hindi-name {
        font-size: 1.75rem;
        font-weight: 400;
        padding-bottom: 1rem;
    }
    .home-subtitle {
        font-size: 1.5rem;
    }
}