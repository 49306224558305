.publications {
    text-align: left;
    padding: 3rem 2rem 7rem;
    width: 66%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    font-size: 1.25rem;
}

.pub-content {
    margin: 0.75rem 0;
}

.publication-link {
    color: black;
}

.formats-body {
    display: flex;
    justify-content: left;
    column-gap: 3rem;
    row-gap: 2rem;
    flex-wrap: wrap;
    padding-bottom: 5rem;
}

.publication-banner-image {
    width: 100%;
    height: 30rem;
    background-image: url("../../assets/contact2.jpeg");
    background-size: cover;
    background-position: bottom center;
    margin-top: -74px;
}

.video-section {
    width: 45%;
}

.video-content {
    padding: 2rem 0;
    text-align: justify;
}

.video-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}

.image-container {
    overflow: hidden;
    position: relative;
}

.video-image {
    width: 100%;
}

.video-container iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.video-container img {
    left: 0;
    top: 0;
    /* height: 100%; */
    /* width: 100%; */
    position: absolute;
}

@media only screen and (max-width: 860px) {
    .publications {
        width: unset;
        padding: 2.5rem 1.5rem;
    }

    .video-section {
        width: 100%;
        padding-bottom: 5rem;
    }
}