.about {
    padding: 5rem 3rem;
    text-align: left;
    font-size: 1.25rem;
}

.ltr {
    display: flex;
    padding-bottom: 8rem;
}

.about-img {
    width: 45%;
}

.about-content {
    padding: 0 0 0 5rem;
}

.about-text {
    line-height: 2rem;
}

.about-sub-text {
    padding: 1rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
}

.about-content-2 {
    padding: 0 5rem 0 0;
}

.about-text-2 div {
    line-height: 2rem;
    padding: 1rem 0;
}

.about-link {
    color: black;
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 860px) {
    .about-img {
        width: 100%;
        padding-top: 1rem;
        max-height: 23rem;
    }

    .about-content {
        padding: 0;
    }

    .about {
        padding: 3rem 2rem;
    }

    .about-content-2 {
        padding: 0;
    }

    .ltr {
        flex-wrap: wrap;
        padding-bottom: 2rem;
    }
}