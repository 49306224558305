.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
}
.footer-social-media-icon {
    height: 2rem;
    width: 2rem !important;
    margin: 0.5rem 0.75rem;
    color: black;
}