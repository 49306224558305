.contact {
    text-align: left;
    padding: 2rem 2rem 5rem;
    height: 100%;
    flex: 1;
    display: flex;
}

.contact-heading {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    flex: 2;
    padding: 0 3rem 0 0;
}

.mail-form {
    font-size: 1.25rem;
    flex: 3;
    padding: 2rem 0 0 1rem;
    box-sizing: border-box;
}

.mail-input {
    width: 100%;
    box-sizing: border-box;
    height: 2rem;
    font-size: 1rem;
    margin: 0.5rem 0 1rem;
    font-family: 'EB Garamond', serif;
}

.mail-input-area {
    width: 100%;
    box-sizing: border-box;
    height: 6rem;
    font-size: 1rem;
    margin: 0.5rem 0;
    font-family: 'EB Garamond', serif;
}

.error-message {
    color: red;
    font-size: 1rem;
}

.submit-button {
    background-color: black;
    border: none;
    outline: none;
    color: white;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    border-radius: 5px;
    margin-top: 1rem;
    cursor: pointer;
}

.contact-social-media-icon {
    height: 1.5rem;
    margin: 1.5rem 0.125rem 0 -1rem;
    color: black;
}

.contact-banner-image {
    width: 100%;
    height: 30rem;
    background-image: url("../../assets/events.jpg");
    background-size: cover;
    background-position: center;
    margin-top: -74px;
}


@media only screen and (max-width: 860px) {
    .contact {
        padding: 0 1rem;
        flex-direction: column;
    }

    .mail-form {
        padding: 2rem 0 0;
    }

    .contact-heading {
        padding: 0;
    }
}