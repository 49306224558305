.gallery {
    text-align: left;
    padding: 2rem 1rem 5rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    font-size: 1.25rem;
}

.gallery-body {
    display: flex;
    justify-content: left;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.gallery-section {
    flex: 1;
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    min-width: 30%;
    max-width: 32%;
}

.gallery-image {
    width: 100%;
    max-height: 450px;
}

.gallery-content {
    padding: 0.5rem 0;
    text-align: justify;
}

.image-container {
    min-height: 450px;
}

@media only screen and (max-width: 860px) {
    .gallery-body {
        flex-direction: column;
    }

    .gallery-section {
        padding: 3rem 0;
        min-width: unset;
        max-width: unset;
    }
}