.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 99;
}

.header-black {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 99;
    color: white;
    background: rgba(0, 0, 0, 0.5)
}

.header-icon {
    height: 1.5rem !important;
    margin: 0 0.75rem 0 0.25rem;
    cursor: pointer;
    color: inherit;
    font-size: 1.5rem;
}

.name {
    cursor: pointer;
    outline: none;
    font-size: 2rem;
}

.header-right {
    display: flex;
    flex: 1;
}

.menu {
    font-size: 1.25rem;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin: 0 1rem;
}

.menu-item {
    margin: 0 0.5rem;
    cursor: pointer;
    outline: none;
    background-color: transparent;
}

.social-media {
    display: flex;
    align-items: center;
    color: white;
}

.social-media-black {
    display: flex;
    align-items: center;
    color: black;
}

.active {
    text-decoration: underline;
}

.menu-wrapper {
    display: flex;
    align-items: center;
    color: inherit;
}

.menu-icon {
    display: none;
}

.mobile-menu {
    display: none;
}

.mail-icon-white {
    height: 1.5rem;
    /* margin-bottom: -0.15rem; */
}


@media only screen and (max-width: 860px) {
    .header-right {
        display: none;
    }

    .header {
        padding: 1rem;
    }

    .menu-icon {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        outline: none;
    }

    .header-icon {
        height: 2.25rem;
        margin: 0 0.5rem;
        padding: 1rem 0;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        z-index: 99;
        color: black;
        align-items: center;
    }

    .social-media a {
        color: black;
    }

    .name-black {
        cursor: pointer;
        outline: none;
        font-size: 2rem;
    }

    .header-white {
        background-color: white;
        color: black;
        width: 100%;
        box-sizing: border-box;
    }

    .header-black {
        padding: 1rem;
    }

    .close-icon {
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 2rem;
        font-weight: 100;
    }

    .menu-options {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        font-size: 2rem;
        line-height: 3rem;
    }
}