body {
  margin: 0;
  font-family: 'EB Garamond', serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 2.5rem;
  margin: 2rem 0;
  font-weight: 500;
}
@media only screen and (max-width: 860px) {
  h2 {
    margin: 1rem 0;
    font-size: 2rem;
  }
}